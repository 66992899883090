<template>
  <div class="container intake-categories-container">
    <div class="row">
      <div class="col-12">
        <router-view class="mt-3"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "intakeCategories",
  mounted() {
    this.getIntakeCategories();
  },
  methods: {
    ...mapActions({
      getIntakeCategories: "intakeForms/getCategories",
    }),
  },
};
</script>
